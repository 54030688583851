import React, { Fragment, useEffect, useState } from 'react';
import { Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import { H6, Image, LI, UL, P } from '../../AbstractElements';
import man from '../../assets/images/dashboard/1.png';
import {get_infos } from '../../Services/Cash.services'

const Profile = () => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [bank, setBank] = useState(0);
    const [cash, setCash] = useState(0);
    const photo = localStorage.getItem('user_photo') ? localStorage.getItem('user_photo') : man

    useEffect(() => {
        let infos = JSON.parse(localStorage.getItem("infos")); 
        setName(infos.full_name);
        setCompany(infos.company_name);
    }, [name, company]);

    useEffect(() => {
        get_infos().then((infos) => {
            setBank(infos.bank_amount);
            setCash(infos.cash_amount);
        })
    })

    return (
        <Fragment>
            <div className="sidebar-user text-center">
               <a className="setting-primary" href="#javascript">
                    <Settings />
                </a>
                <Link to='/dashboard'>
                    <Image attrImage={{ className: 'img-90 rounded-circle', src: photo,  alt: '' }} />
                    <H6 attrH6={{ className: 'mt-3 f-14 f-w-600' }} >{name}</H6>
                </Link>
                <P attrPara={{ className: 'mb-0 font-roboto' }} >{company}</P>
                <UL attrUL={{ className: 'flex-row simple-list' }}>
                    <LI>
                        <span>{bank}</span>
                        <P>Banque</P>
                    </LI>
                   <LI>
                        <span>{cash}</span>
                        <P>Caisse</P>
                    </LI>
                </UL>
            </div>
        </Fragment >
    );
};

export default Profile;