import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const [currentUser, setCurrentUser] = useState(false);
    const [authenticated, setAuthenticated] = useState(false)
    const jwt_token = localStorage.getItem('token');

   

    return (
        currentUser !== null || authenticated || jwt_token ?
            <Outlet />
            :
            <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
    );
}

export default PrivateRoute;

