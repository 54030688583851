import React, { Fragment, useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { Btn, LI } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import { get_infos } from '../../../Services/Cash.services';

const FiscalYear = () => {

    const [infos, setInfos] = useState("");

    useEffect( () =>{
        get_infos().then((response) => {
            setInfos(response.exercise_year);
       })
       
    },[setInfos]);
    

    return (
        <Fragment>
            <LI attrLI={{ className: 'onhover-dropdown p-0' }} >
                <Btn attrBtn={{ as: Card.Header, className: 'btn btn-primary-light',  }} >
                    <Link to='/FiscalYear'>
                        {infos}
                    </Link>
                </Btn>
            </LI>
        </Fragment>
    );
};

export default FiscalYear;