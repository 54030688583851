
import { Downloadmodal } from "../CommonModal"
import { useEffect, useState } from "react";
import { getFundReport } from "../Services/funds.services";

const FundReport = () => {

  const [confirm, setConfirm] = useState(false);
  const [url, setUrl] = useState("");
  const [disable, setDisable] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setConfirm(!confirm);

  useEffect(()=> {
    setConfirm(true);
    handleDownload();

  },[])

  const handleDownload = () =>{
    setModalBody("Votre document est en cours de téléchargement");
    setConfirm(true);
    getFundReport().then((result) => {
        setModalBody(result.message);
        if (result.code === 0){
          setDisable(false)
          setUrl(result.path)
        }
    })
  }

    return(
        <Downloadmodal isOpen={confirm} 
                       toggler={toggle} 
                       title={`Gestion de la trésorerie`}
                       url={url}
                       disabled= {disable}
                       message={modalBody}/>
    )
}

export default FundReport;