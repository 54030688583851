import React, { Fragment, useEffect, useState } from 'react';
import { Btn, H4, H5, H6, UL, LI, Alerts } from '../AbstractElements';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Facebook, Linkedin } from 'react-feather';
import {siteURL} from "../Services/AxiosInstance";
import axios from 'axios';

const SECRET_KEY = "c6&3au1t&z9@a&2mbka1)d*i-u7k)z5du8x+5!i&b1yxmvm^z^"

const LoginForm = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 
    const {iwoToken} = props
    const reset_password_url = siteURL()+"/password_reset/"

    useEffect(()  => {
        if(iwoToken){
            //let token = AES.decrypt(iwoToken, SECRET_KEY).toString(enc.Utf8)
            //console.log(token);
            axios.get(siteURL()+"/auth/get_infos/",{ headers: {"Content-Type": "application/json", "Authorization": "Token "+iwoToken, 'Accept' : 'application/json'} }
            ).then(result => {
                result = result.data;
                if(result.code && result.has_access){
                    localStorage.setItem("IwoCashToken", result.token);
                    localStorage.setItem("country_name", result.country_name)
                    localStorage.setItem("company_id", result.company_id);
                    localStorage.setItem("company_name", result.company_name);
                    localStorage.setItem("company_logo", result.company_logo);
                    localStorage.setItem("username", result.username);
                    localStorage.setItem("full_name", result.full_name);
                    localStorage.setItem("entity_name", result.entity_name);
                    localStorage.setItem("entity_id", result.entity_id);
                    localStorage.setItem("infos", JSON.stringify(result));
                    window.location.reload(false);
                }
            }).catch((error) => {
                console.log(error)
            });
            
        }
    }, [iwoToken]); 

    const handleSubmit = (e) => {
        e.preventDefault();
        if(username && password){
            axios.post(siteURL()+"/auth/get_token_auth/", {username: username, password:password},{ headers: {"Content-Type": "application/json" } }
           ).then(result => {
                result = result.data;
                if(result.code === 200 ){
                    if(result.has_access){
                        localStorage.setItem("IwoCashToken", result.token);
                        localStorage.setItem("country_name", result.country_name)
                        localStorage.setItem("company_id", result.company_id);
                        localStorage.setItem("company_name", result.company_name);
                        localStorage.setItem("company_logo", result.company_logo);
                        localStorage.setItem("username", result.username);
                        localStorage.setItem("full_name", result.full_name);
                        localStorage.setItem("entity_name", result.entity_name);
                        localStorage.setItem("infos", JSON.stringify(result));
                        localStorage.setItem("entity_id", result.entity_id);
                        window.location.reload(false);
                        //navigate("/dashboard")
                    }
                    else {
                        setMessage("Accès refusé");
                        setShowError(true);
                    }
                }
                else{
                    console.log(result)
                    setMessage(result.msg);
                    setShowError(true)
                } 
            }).catch( error =>{
                setMessage("Un problème est survenu. Merci de réessayer")
            })
        }
    }

    return (
        <Fragment>
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleSubmit} >
                    <H4 attrH4={{className:"txt-primary"}}>IWO caisse</H4>
                    <H6>Bienvenue sur votre caisse digitale</H6>
                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                    <FormGroup>
                        <Label>Adresse mail</Label>
                        <div className="input-group"><span className="input-group-text"><i className="icon-email"></i></span>
                            <Input className="form-control" name="email" type="email" required="" placeholder="xxx@xxx.com" onChange={(event) => setUsername(event.target.value)} />
                        </div>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label>Mot de passe</Label>
                        <div className="input-group"><span className="input-group-text"><i className="icon-lock"></i></span>
                            <Input className="form-control" type="password" name="password" required="" placeholder="*********" onChange={(event) => setPassword(event.target.value)} />
                        </div>
                    </FormGroup>
                    
                    <FormGroup>
                        <a style={{color: 'secondary'}} href={reset_password_url} target="blank">Mot de passe oublié ? </a>
                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Se connecter</Btn>
                    </FormGroup>
                    <div className="login-social-title">
                        <H5>Suivez nous sur </H5>
                    </div>
                    <FormGroup>
                        <UL attrUL={{ as: 'ul', className: 'login-social flex-row' }} >
                            <LI><a href="https://www.linkedin.com/login" ><Linkedin /></a></LI>
                            <LI><a href="https://www.linkedin.com/login" ><Facebook /></a></LI>
                        </UL>
                    </FormGroup>
                    
                </Form>
            </div>
        </Fragment>
    );
};

export default LoginForm;