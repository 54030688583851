import React, { Fragment, useState, useEffect } from 'react';
import CustomTable from '../CustomTables';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../AbstractElements';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import { listEval, deleteEval, ValidateEval } from '../Services/funds.services';
import { DeleteButton, EditButton, ValidateButton } from '../CustomButton';
import { useNavigate, useParams } from "react-router-dom";
import CommonModal from '../CommonModal';
import { TYPE_AMORTIZATION, TYPE_CLAIM, TYPE_STOCK, TYPE_DEBT } from '../Params';

const Eval = () => {

  const [data, setData] = useState([]);
  const [select, setSelect] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [title, setTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false); 
  const [update, setUpdate] = useState(false);

  const toggle = () => setConfirm(!confirm);

  const navigate = useNavigate();

  const {type} = useParams();

  useEffect(()=> {
    if(parseInt(type) === TYPE_AMORTIZATION){
        setTitle("Amortissements");
    }
    else if(parseInt(type) === TYPE_DEBT){
        setTitle("Dettes");
    }
    else if(parseInt(type) === TYPE_CLAIM){
      setTitle("Créances");
    }
    else if(parseInt(type) === TYPE_STOCK){
      setTitle("Stock");
    }
  }, []);

  const tableColumns = [
    {
        name:'Actions',
        selector: (row) => 
            <div>
                {row.can_edit ? <EditButton id={row.id} handleClick={handleEdit} /> : <></>}
                &nbsp;&nbsp;{row.operation_status === 'Brouillon' ? <ValidateButton id={row.id} handleClick={handleValidate}/> : <></>}
                &nbsp;&nbsp;{row.can_edit ? <DeleteButton id={row.id} confirm={confirmDelete} />:<></> }
            </div>
    },
    {
      name: 'Date',
      selector: (row) => row.readable_date,
      sortable: true,
      filterable : true,
      
    },
    {
      name: 'Libellé',
      selector: (row) => row.name,
      sortable: true,
      filterable : true,
      
    },
    {
        name: 'Statut',
        selector: (row) => row.operation_status,
        sortable: true,
        filterable : true,
        
    },
    {
        name: 'Montant',
        selector: (row) => row.amount,
        sortable: true,
        filterable : true,    
    }
  ];
  
  const handleDelete = () => {
    deleteEval(select).then((res) => {
        setMessage(res.message);
        setShowError(true);
    })
    setConfirm(false);
    setUpdate(true);
  }

  const handleEdit = (id) => {
    navigate(`/EditEval/${type}/${id}`);
  } 

  const handleNew = () => {
    navigate(`/AddEval/${type}`);
  } 

  const confirmDelete = (id) => {
    setSelect(id)
    setModalBody("Voulez-vous vraiment supprimer cette opération ?")
    setConfirm(true);
  }
  

  const handleValidate = (id) => {
    ValidateEval(id).then((res) => {
        setMessage(res.message);
        setShowError(true);
        setUpdate(true);
    })
}

  useEffect(() => {
    listEval(type).then((result) => {
        setData(result.data)
        setUpdate(false);
    });
  }, [update])


  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title={title}/>
              <CardBody>
                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                    <CommonModal isOpen={confirm} 
                                 toggler={toggle} 
                                 title="Suppression"
                                 action={handleDelete}
                                 message={modalBody}/>
                        <Row>
                            <Col md='3'>
                                <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'button', onClick:handleNew }}>Nouveau</Btn>
                            </Col>
                        </Row>
                        
                    <div className='table-responsive display' style={{marginTop: '2%' }}>
                        <CustomTable
                            data={data}
                            columns={tableColumns}
                        />
                    </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default Eval;