import { Fragment, useState, useEffect } from "react"
import { CardBody, Card} from 'reactstrap';
import HeaderCard from "../Components/Common/Component/HeaderCard";
import { H6, LI, P, UL,  Alerts } from '../AbstractElements';
import { getFiles, deleteFile, downloadFile } from "../Services/Files.service";
import CommonModal from "../CommonModal";

 

 const Files = () => {

    const [files, setFiles] = useState([]);
    const [select, setSelect] = useState(0);
    const [confirm, setConfirm] = useState(false);
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 
    const [update, setUpdate] = useState(false);

    const toggle = () => setConfirm(!confirm);

    useEffect(() => {
        getFiles().then((res) => {
            console.log(res);
            setFiles(res);
        })
    },[update])

    const confirmDelete = (id) => {
        setSelect(id)
        setConfirm(true);
    }

    const handleDelete = () => {
        deleteFile(select).then((res) => {
            setMessage(res.message);
            setShowError(true);
            setUpdate(true);
        });
    }

    const handleDownload = (id) => {
        downloadFile(id).then((res) => {
            if (res.code === 200){
                const link = document.createElement('a');
                link.href = res.path;
                link.setAttribute(
                  'download',
                  `${res.filename}`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
             }
        })
            
    }

    return (
        <Fragment>
            <div className="file-content">
                <Card className="o-hidden">
                    <HeaderCard title="Pièces comptables" />
                    <CardBody className="file-manager">
                        {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                        <CommonModal isOpen={confirm} 
                                 toggler={toggle} 
                                 title="Supression" 
                                 action={handleDelete}
                                 message="Voulez-vous vraiment suprrimer ce fichier ?"/>
                        <UL attrUL={{ className: 'simple-list files' }}>
                        {files.map((item, i) => {
                            return(
                                <LI attrLI={{ className: 'file-box', style:{marginTop:'1%'} }} key={i}>
                                    <div className="file-top">
                                        <i className="fa fa-file-image-o txt-primary" ></i>
                                        {item.removed ? <span className="text-danger sweet-11" onClick={() => confirmDelete(item.id)}><i className="fa fa-times f-14 ellips txt-danger"></i></span> : <></>}
                                    </div>
                                    <div className="file-bottom">
                                        <H6>{item.file_name}</H6>
                                        <P className="mb-1"></P>
                                        <P><span>size : {item.size} ko </span></P>
                                        <P><a onClick={() => handleDownload(item.id)} style={{float:'right'}} className="btn btn-outline-success"><i className="fa fa-download "></i></a></P>
                                    </div>
                                </LI>)
                            })}
                        </UL>
                       
                    </CardBody>
                </Card>
            </div> 
        </Fragment>
    )
 }

 export default Files;