import {axiosInstance, ACCOUNTING_URL, CASH_URL} from "./AxiosInstance";



export const evalConfig = async (id, config_type) => {
    const params = (config_type ? `${config_type}/` : '');
    let res = await axiosInstance.get(`${CASH_URL}/evaluation_config/${params}`)
    return res.data
}

export const updateConfig = async (id, data, config_type) => {
    let res = await axiosInstance.put(`${CASH_URL}/evaluation_config/${config_type}/${id}/`,data)
    return res.data
}

// accounts methods

export const getAccounts = async (id) => {
    const params = (id ? `${id}/` : '');
    let res = await axiosInstance.get(`${ACCOUNTING_URL}/account/${params}`)
    return res.data
} 

export const addAccounts = async(data) => {
    let res = await axiosInstance.post(`${ACCOUNTING_URL}/account/`, data)
    return res.data
}

export const updateAccounts = async(id, data) => {
    let res = await axiosInstance.put(`${ACCOUNTING_URL}/account/${id}/`, data)
    return res.data
}

export const deleteAccount = async(id) => {
    let res = await axiosInstance.delete(`${ACCOUNTING_URL}/account/${id}/`)
    return res.data
}

export const convert_to_select_options = (data, name) => {
    let converted_data = []
    data.map((res) => {
        converted_data.push({value: res.id, label:`${res['code']}-${res[name]}`})
    })
    return converted_data
}

// journal methods 

export const getJournals = async (id) => {
    const params = (id ? `${id}/`: '');
    let res = await axiosInstance.get(`${ACCOUNTING_URL}/journal/${params}`)
    return res.data
} 

export const addJournal = async(data) => {
    let res = await axiosInstance.post(`${ACCOUNTING_URL}/journal/`, data)
    return res.data
}

export const updateJournal = async(id, data) => {
    let res = await axiosInstance.put(`${ACCOUNTING_URL}/journal/${id}/`, data)
    return res.data
}

export const deleteJournal = async(id) => {
    let res = await axiosInstance.delete(`${ACCOUNTING_URL}/journal/${id}/`)
    return res.data
}

// cash settings config

export const listCashConfig = async (type) => {
    let res = await axiosInstance.get(`${CASH_URL}/elements/element_type/${type}/`)
    return res.data
}

export const getCashConfig = async (id) => {
    let res = await axiosInstance.get(`${CASH_URL}/elements/${id}/`)
    return res.data
}

export const addCashConfig = async (type, data) => {
    let res = await axiosInstance.post(`${CASH_URL}/elements/element_type/${type}/`, data)
    return res.data
}

export const updateCashConfig = async(id, data) => {
    let res = await axiosInstance.put(`${CASH_URL}/elements/${id}/`, data)
    return res.data
}

export const deleteCashConfig = async(id) => {
    let res = await axiosInstance.delete(`${CASH_URL}/elements/${id}/`)
    return res.data
}


// projects settings
export const getProject = async (id) => {
    let params = (id ? '?id='+id : '');
    let res = await axiosInstance.get(`/project/projects/${params}`)
    return res.data
} 

export const addProject = async(data) => {
    let res = await axiosInstance.post("/project/projects/", data)
    return res.data
}

export const updateProject = async(id, data) => {
    let res = await axiosInstance.put(`/project/projects/${id}/`, data)
    return res.data
}

export const deleteProject = async(id) => {
    let res = await axiosInstance.delete(`/project/projects/${id}/`)
    return res.data
}

// Fiscal Year APIs 
export const fiscalYear = async() => {
    let res = await axiosInstance.get(`${ACCOUNTING_URL}/fiscal_year/`)
    return res.data
}

export const addFiscalYear = async(data) => {
    let res = await axiosInstance.post(`${ACCOUNTING_URL}/fiscal_year/`, data)
    return res.data
}

export const deleteFiscalYear = async(id) => {
    let res = await axiosInstance.delete(`${ACCOUNTING_URL}/fiscal_year/${id}/`)
    return res.data
}

export const changeFiscalYear = async(id) => {
    let res = await axiosInstance.put(`${ACCOUNTING_URL}/set_default_fiscal_year/${id}/`)
    return res.data
}

export const closeFiscalYear = async(id) => {
    let res = await axiosInstance.put(`${ACCOUNTING_URL}/close_fiscal_year/${id}/`)
    return res.data
}