import axiosInstance from "./AxiosInstance";

export const get_expenses = async (project_id) => {
    let params = (project_id ? '?project_id='+project_id : '');
    let data = await axiosInstance.get(`/stats/expenses/${params}`);
    return data.data
    
}

export const get_products = async (project_id) => {
    let params = (project_id ? '?project_id='+project_id : '');
    let data = await axiosInstance.get(`/stats/products/${params}`);
    return data.data
    
}

export const get_total_prod_exp = async (project_id) => {
    let params = (project_id ? '?project_id='+project_id : '');
    let data = await axiosInstance.get(`/stats/total_ex_prod/${params}`);
    return data.data
    
}

export const  extract_array =  (data, key) => {
    let result = [];
    for (let i=0; i<data.length; i++){
        result.push(data[i][key])
    }
    return result
}
