import React, { Fragment, useState, useEffect } from 'react';
import CustomTable from '../../CustomTables';
import { Container, Row, Col, Card, CardBody, Form, Input } from 'reactstrap';
import {  Btn, Alerts } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import {getAccounts} from '../../Services/Settings.service'
import { addAccounts, deleteAccount } from '../../Services/Settings.service';
import { DeleteButton, EditButton } from '../../CustomButton';
import { useNavigate } from "react-router-dom";
import CommonModal from '../../CommonModal';

const Accounts = () => {

  const [data, setData] = useState([]);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [select, setSelect] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false); 
  const [update, setUpdate] = useState(false);

  const toggle = () => setConfirm(!confirm);

  const navigate = useNavigate();

  const tableColumns = [
    {
        name:'Actions',
        
        selector: (row) => 
            <div>
                <EditButton id={row.id} handleClick={handleEdit} />
                &nbsp;&nbsp;<DeleteButton id={row.id} confirm={confirmDelete} />
            </div>
    },
    {
      name: 'Code',
      selector: (row) => row.code,
      sortable: true,
      filterable : true,
    },
    {
      name: 'Nom',
      selector: (row) => row.nom,
      sortable: true,
      filterable: true,
    },
  ];
  
  const handleDelete = () => {
    
    deleteAccount(select).then((res) => {
        setMessage(res.message);
        setShowError(true);
    })
    setConfirm(false);
    setUpdate(true);
  }

  const handleEdit = (id) => {
    navigate("/EditAccount/"+id);
  } 

  const confirmDelete = (id) => {
    setSelect(id)
    setConfirm(true);
  }

  useEffect(() => {
    getAccounts().then((result) => {
        setData(result)
        setUpdate(false);
    });
  }, [update])

  const newAccount = (e) => {
    e.preventDefault();
    addAccounts({nom:name, code:code}).then((res) => {
        setMessage(res.message);
        setShowError(true);
        setUpdate(true);
    })
  }

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Plan Comptable"/>
              <CardBody>
                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                    <CommonModal isOpen={confirm} 
                                 toggler={toggle} 
                                 title="Supression" 
                                 action={handleDelete}
                                 message="Voulez-vous vraiment suprrimer ce compte ?"/>
                    <Form className="needs-validation" noValidate="" onSubmit={newAccount}>
                        <Row className="g-3">
                            <Col md='3'>
                                <Input className="form-control" 
                                        name="code" 
                                        type="text" 
                                        required={true} 
                                        placeholder="code du compte"
                                        onChange={(e) => setCode(e.target.value)}
                                />
                            </Col>
                            <Col md='3'>
                                <Input 
                                    className="form-control" 
                                    name="name" 
                                    type="text" 
                                    required={true} 
                                    placeholder="libellé du compte" 
                                    onChange={(e) => setName(e.target.value)}
                                    />
                            </Col>
                            <Col md='3'>
                                <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Ajouter</Btn>
                            </Col>
                        </Row>
                    </Form>        
                    <div className='table-responsive display' style={{marginTop: '2%' }}>
                        <CustomTable
                            data={data}
                            columns={tableColumns}
                        />
                    </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default Accounts;