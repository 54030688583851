import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { authRoutes } from './AuthRoutes';
import LayoutRoutes from '../Route/LayoutRoutes';
import Signin from '../Auth/Signin';
import PrivateRoute from './PrivateRoute';


function isLogin(){
        return localStorage.getItem("IwoCashToken") ?  true :  false
        /* if(localStorage.getItem("IwoCashToken") !== undefined ){
            return true;
        }
        return false; */
    }

const Routers = () => {
    let auth = isLogin();
    return (
        <BrowserRouter basename={'/'}>
            <>
                <Routes>
                    <Route path={'/'} element={<PrivateRoute />}>
                        {auth === true ?
                            <>
                                <Route exact path='' element={<Navigate to='/dashboard/' />} />
                                <Route exact path={`/`} element={<Navigate to='/dashboard/' />} />
                                <Route exact path={`login`} element={<Navigate to='/dashboard/' />} />
                                <Route path={`/*`} element={<LayoutRoutes />} />
                                {authRoutes.map(({ path, Component }, i) => (
                                    <Route path={path} element={Component} key={i} />
                                ))}
                            </> : 
                            <>
                                <Route exact path='' element={<Signin />} />
                                <Route exact path={`/`} element={<Signin />} />
                                <Route exact path={`/SignIn/:IwoToken:/`} element={<Signin />} />
                                <Route exact path={`/*`} element={<Signin />} />
                            </>
                            
                        }
                    </Route>                        
                </Routes>
            </>
        </BrowserRouter>
    );
};

export default Routers;