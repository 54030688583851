import React from 'react';
import { Trash, Edit, Check, Upload, Lock, CheckSquare, CheckCircle  } from "react-feather"
import { Btn } from './AbstractElements';
import { Button } from 'reactstrap';

export const DeleteButton = (props) => {
    let {id, confirm} = props
    return (<Trash className="txt-danger" size={18} onClick={() => confirm(id)} />)
}

export const ActivateButton = (props) => {
    let {id, confirm} = props
    return (<Check className="txt-success" size={18} onClick={() => confirm(id)} />)
}

export const LockButton = (props) => {
    let {id, confirm} = props
    return (<Lock className="txt-danger" size={18} onClick={() => confirm(id)} />)
}

export const EditButton = (props) => {
    let {id, handleClick} = props
    return (<Edit  className="txt-primary" size={18} onClick={() =>handleClick(id)}/>)
}

export const ValidateButton = (props) => {
    let {id, handleClick} = props
    return (<Check size={18} className="txt-success" onClick={() =>handleClick(id)}/>)
}

export const ValidateFiscal = (props) => {
    let {id, handleClick} = props
    return (<Button className='btn btn-sm' color='primary' onClick={() =>handleClick(id)}> Clôturer </Button>)
}

export const UploadButton = (props) => {
    let {id, handleClick} = props
    return (<Upload className="txt-infos" size={18} onClick={() =>handleClick(id)}/>)
}




