import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { getAccounts, updateAccounts  } from '../../Services/Settings.service';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../Common/Breadcrumbs';


const EditAccount = () => {
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 
    const [code, setCode] = useState("");
    
    let {id} = useParams()
    const elements = [{'label': 'Plan comptable', 'link': '/Accounts'}, {'label': 'Edition de compte', 'link': '/EditAccount/'+id}]

    useEffect(() => {
        getAccounts(id).then((res)=>{
            let result = res[0]
            setName(result.nom);
            setCode(result.code);
        })
        
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {'nom': name, "code":code, }
        updateAccounts(id, data).then((response) => {
            setMessage(response.message)
            setShowError(true)
        })
    }


    return (
        <Fragment>
            <Breadcrumbs elements={elements} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`Paramètrage du compte ${code}`}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <Input className="form-control" 
                                                name="code" type="text" required="" value={code} 
                                                onChange={(e) => {setCode(e.target.value)}}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" type="text" required="" 
                                                value={name}
                                                onChange={(e) => {setName(e.target.value)}}/>
                                    </FormGroup>
                    
                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Modifier</Btn>
                                    </FormGroup>
                                </Form>
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}


export default EditAccount;