import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../AbstractElements';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import { updateCash, convert_to_select_options, getCash } from '../Services/funds.services';
import { listCashConfig, getProject } from '../Services/Settings.service';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { TYPE_ELEMENT, TYPE_MEANS_OF_PAYMENT, INFLOW, OUTFLOW } from '../Params';
import Breadcrumbs from '../Common/Breadcrumbs';
import DatePicker from 'react-datepicker';

const EditCash = () => {
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [amount, setAmount] = useState("");
    const [element, setElement] = useState({});
    const [mop, setMOP] = useState({});
    const [elements, setElements] = useState([]);
    const [mops, setMOPs] = useState([]);
    const [project, setProject] = useState({});
    const [projects, setProjects] = useState([]);
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    const [title, setTitle] = useState("");
    const [label, setLabel] = useState("");

    let {type, id} = useParams()

    useEffect(() => {
        let value = []
        switch(parseInt(type)){
            case INFLOW:
                value = [{'label': 'Entrée de fond', 'link': `/Cash/${INFLOW}`},{'label': "Edition d'une entrée de fonds", 'link': `/EditFunds/${type}/${id}`}]
                setLabel("Edition d'une entrée de fonds");
                break;
            case OUTFLOW:
                value = [{'label': 'Sortie de fond', 'link': `/Cash/${OUTFLOW}`},{'label': "Edition d'une sortie de fonds", 'link': `/AddFunds/${type}/${id}`}]
                setLabel("Edition d'une sortie de fonds")
                break;
            default:
                value = "je suis perdu"
                break;
        }
        setTitle(<Breadcrumbs elements={value}/>)
    }, [])
    

    

    useEffect(() => {
        getProject().then((res)=>{setProjects(convert_to_select_options(res))})
    },[]);

    useEffect(() => {
        listCashConfig(TYPE_ELEMENT).then((res)=>{setElements(convert_to_select_options(res))})
    },[]);

    useEffect(() => {
        listCashConfig(TYPE_MEANS_OF_PAYMENT).then((res)=>{setMOPs(convert_to_select_options(res))})
    },[]);

   
    useEffect(() => {
        getCash(id).then((res)=>{
            let result = res;
            setDate(new Date((result.date * 1000) + 1000))
            setName(result.name)
            setAmount(result.amount)
            setElement({label:result.element_name, value:result.element})
            setMOP({label:result.means_of_payment_name, value:result.means_of_payment})
            setProject({label:result.project_name, value:result.project})
        })
        
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('type', type);
        formData.append('element', element.value);
        formData.append('date', parseInt((new Date(date).getTime() / 1000).toFixed(0)));
        formData.append('means_of_payment', mop.value);
        formData.append('name',name);
        formData.append('amount', amount);
        if (project.value){
            formData.append('project', project.value)
        }
        updateCash(id, formData).then((res) => {
            setMessage(res.message);
            setShowError(true);
        })
    }


    return (
        <Fragment>
            {title}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={label}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    
                                    <FormGroup>
                                        <Label>Date</Label>
                                        <DatePicker className="form-control digits" 
                                                required="" 
                                                selected={date}
                                                onChange={(da) => {setDate(da)}}
                                                dateFormat='dd/MM/yyyy'
                                                />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" 
                                                type="text" 
                                                required=""
                                                value={name}
                                                onChange={(e) => {setName(e.target.value)}}
                                                />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Montant</Label>
                                        <Input className="form-control" 
                                                name="name" 
                                                type="number" 
                                                min="0" 
                                                value={amount}
                                                onChange={(e) => {setAmount(e.target.value)}}
                                                />
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Eléments</Label>
                                        <Select options={elements}
                                                onChange={(value => {setElement(value)})}
                                                value={element}
                                                className="js-example-basic-single col-sm-12"/>
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Moyens de paiement</Label>
                                        <Select options={mops}
                                                onChange={(value => {setMOP(value)})}
                                                value={mop}
                                                className="js-example-basic-single col-sm-12"/>
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Projet</Label>
                                        <Select options={projects} 
                                                className="js-example-basic-single col-sm-12"
                                                value={project}
                                                onChange={(value => {setProject(value)})}
                                                />
                                    </FormGroup>
                    
                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Modifier</Btn>
                                    </FormGroup>
                                </Form>
                                {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}
 



export default EditCash;