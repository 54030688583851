import { Settings, PlusSquare, MinusSquare, ArrowLeft, ArrowRight, Command, BarChart, File, ShoppingCart } from 'react-feather';
import { BLOTTER_TYPE_BANK, BLOTTER_TYPE_CASH, TYPE_AMORTIZATION, TYPE_STOCK, TYPE_DEBT, TYPE_CLAIM, INFLOW, OUTFLOW } from '../../Params';


export const MENUITEMS = [
    {
        menutitle: 'Paramètres',
        Items: [
            { path: 'Dashboard', title: 'Tableau de bord', type: 'link' , icon: BarChart},
            { title: 'Paramètres', icon: Settings, type: 'sub', active: false, children:[
                { path: '/FiscalYear', title: 'Exercices', type: 'link'},
                { path : '/Evaluations', title: 'Evaluations', type:'link'},
                // { path: '/Accounts', title: 'Plan comptable', type: 'link'},
                { path: '/Journals', title: 'Journaux', type: 'link'},
                { path: '/Elements', title: 'Elements', type: 'link'},
                { path: '/MOP', title: 'Moyen de paiement', type: 'link'},
                // { path: '/Projects', title: 'Projets', type: 'link'},
                { path: '/AddCustomer', title: 'Clients', type: 'link'},
            ]},
        ]
    },
    {
        menutitle: 'Fonds',
        Items: [
            { path: `/Cash/${INFLOW}`, title: 'Entrée de fond', type: 'link' , icon: PlusSquare, style:{"fontSize":"16px"}},
            { path: `/Cash/${OUTFLOW}`, title: 'Sortie de fond', type: 'link', icon: MinusSquare, style:{"fontSize":"16px"} },
        ]
    },
    {
        menutitle: 'Evaluations',
        Items: [
            { path: `/Eval/${TYPE_AMORTIZATION}`, title: 'Amortissements', type: 'link' , icon: BarChart},
            { path: `/Eval/${TYPE_STOCK}`, title: 'Stock', type: 'link', icon: Command },
            { path: `/Eval/${TYPE_DEBT}`, title: 'Dettes', type: 'link', icon: ArrowLeft },
            { path: `/Eval/${TYPE_CLAIM}`, title: 'Créances', type: 'link', icon: ArrowRight },
        ]
    },
    {
        menutitle: 'documents',
        Items: [
            { path: `/Blotter/${BLOTTER_TYPE_CASH}`, title: 'Brouillard de caisse', type: 'link' , icon: PlusSquare},
            { path: `/Blotter/${BLOTTER_TYPE_BANK}`, title: 'Brouillard de banque', type: 'link', icon: MinusSquare },
            { path: '/Statement/', title: 'Etats financiers', type: 'link', icon: File },
            { path: '/FundReport/', title: 'Gestion de trésorerie', type: 'link', icon: File },
            { path: '/Files', title: 'Pièces jointes', type: 'link', icon: File },
            
        ]
    },
    
];