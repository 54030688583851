import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import {evalConfig} from '../../Services/Settings.service'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const Evaluation = () => {

  const [data, setData] = useState([]);


  const tableColumns = [
    {
        name:'Actions',
        selector: (row) => <Link className="txt-primary" to={{pathname: `/EditEvaluation/${row.config_type_code}/${row.id}`}}><Edit/></Link>
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      filterable : true,
      
    },
    {
      name: 'Compte débit',
      selector: (row) => row.debit_account_name,
      sortable: true,
      filterable : true,
      
    },
    {
      name: 'Compte débit',
      selector: (row) => row.credit_account_name,
      sortable: true,
      
    },
    {
        name: 'Journal',
        selector: (row) => row.journal_name,
        sortable: true,
        
      },
  ];

  useEffect(() => {
    evalConfig().then((result) => {
        setData(result)
    });
  }, [])


  return (
    <Fragment>
      <Breadcrumbs parent="Paramètres" title="Comptes" mainTitle="Paramètrages des comptes" />
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Paramétrages des comptes"/>
              <CardBody>
                    <div className='table-responsive display'>
                        <DataTable
                            data={data}
                            columns={tableColumns}
                            striped={true}
                            sortable={true}
                            left={true}
                            noDataComponent="Aucune donnée trouvée"
                            pagination={true}
                            paginationServer
                            responsive={true}
                            paginationComponentOptions={{rowsPerPageText:'éléments par page', rangeSeparatorText:'dans'}}
                        />
                    </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default Evaluation;