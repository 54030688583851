import React, { Fragment, useState, useEffect } from 'react';
import Charts from 'react-apexcharts';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import {  expenseOptions, productOptions, global } from './barChart';
import { get_expenses, get_products, extract_array, get_total_prod_exp } from '../Services/Chart.service';
import Select from 'react-select';
import { getProject } from '../Services/Settings.service';
import { convert_to_select_options } from '../Services/funds.services';


const Dashboard = () => {
    const [expenses, setExpenses] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [percProducts, setPercProducts] = useState(0);
    const [percExpenses, setPercExpenses] = useState(0);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({});
    

    useEffect(() => {
      get_expenses(project.value).then((res) => {
        setExpenses([{name : 'Charges', data: extract_array(res, 'value')}]);
      })
    },[setExpenses])

    useEffect(() => {
      get_products(project.value).then((res) => {
        setProducts([{name : 'Produits', data: extract_array(res, 'value')}]);
      })
      
    },[setProducts])

    useEffect(() =>{
      get_total_prod_exp(project.value).then((res) => {
        setTotalProducts(res.products);
        setTotalExpenses(res.expenses);
        setPercProducts(res.perc_products);
        setPercExpenses(res.perc_expenses);
      })
    },[])

    useEffect(() => {
      getProject().then((res) => {
        setProjects(convert_to_select_options(res, 'name'))
      })
    }, []);

    return (<Fragment>
            <Row>
              {/* <Col xl='12'>
                <Card className=''>
                  <CardBody>
                  <Select options={projects}
                          onChange={(value => {setProject(value)})}
                          className="js-example-basic-single col-sm-12"/>
                  </CardBody>
                </Card>
    </Col> */}
                <Col xl="7">
                    <Card className="o-hidden">
                        <HeaderCard title="Comptes des charges" />
                        <div className="Produits">
                            <CardBody className="bottom-content">
                                <Row>
                                    <Col>
                                        <div id="chart-widget4">
                                          <Charts options={expenseOptions.options} series={expenses} type="bar" height={360} /> 
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
                <Col xl="5">
                    <Card className="o-hidden">
                        <CardHeader className="pb-0">
                            <h5 className="txt-primary">Occupation des charges</h5>
                        </CardHeader>
                        <div className="bar-chart-widget">
                            <div className="top-content bg-primary"></div>
                            <CardBody className="bottom-content pt-0">
                                <Row>
                                    <Col>
                                        <div id="chart-widget5">
                                            <Charts options={global.options} series={[percExpenses]} type="radialBar" height={350} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="text-center">
                                    <Col className="col-6 b-r-light">
                                        <div><span className="font-primary">{percExpenses} %</span><span className="text-muted block-bottom">Charges</span>
                                            <h4 className="num m-0"><span className="counter color-bottom"><CountUp end={totalExpenses} duration={5} /></span>F</h4>
                                        </div>
                                    </Col>
                                    <Col className="col-6 b-r-light">
                                        <div><span className="font-primary">{percProducts} %</span><span className="text-muted block-bottom">Produits</span>
                                            <h4 className="num m-0"><span className="counter color-bottom"><CountUp end={totalProducts} duration={5} /></span>F</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
                <Col xl="7">
                    <Card className="o-hidden">
                        <HeaderCard title="Comptes des produits" />
                        <div className="Produits">
                            <CardBody className="bottom-content">
                                <Row>
                                    <Col>
                                        <div id="chart-widget4">
                                          <Charts options={productOptions.options} series={products} type="bar" height={360} /> 
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Fragment>)

}

export default Dashboard;