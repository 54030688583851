import React, { Fragment, useState, useEffect } from 'react';
import CustomTable from '../CustomTables';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../AbstractElements';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import { listCash, deleteCash, ValidateCash, addDocument } from '../Services/funds.services';
import { DeleteButton, EditButton, ValidateButton, UploadButton } from '../CustomButton';
import { useNavigate, useParams } from "react-router-dom";
import CommonModal from '../CommonModal';
import { OUTFLOW, INFLOW } from '../Params';


const Cash = () => {

  const [data, setData] = useState([]);
  const [select, setSelect] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [title, setTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false); 
  const [update, setUpdate] = useState(false);
  const [action, setAction] = useState("");
  const [files , setFiles] = useState([]);
  const [header, setHeader] = useState("");

  const toggle = () => setConfirm(!confirm);

  const navigate = useNavigate();

  const {type} = useParams();

  useEffect(()=> {
    if(parseInt(type) === INFLOW){
        setHeader("Entrée de fonds");
    }
    else if(parseInt(type) === OUTFLOW){
        setHeader("Sortie de fonds");
    }
  }, []);

  const tableColumns = [
    {
        name:'Actions',
        selector: (row) => 
            <div>
                {row.can_edit ? <EditButton id={row.id} handleClick={handleEdit} /> : <></>}
                &nbsp;&nbsp;{row.operation_status === 'Brouillon' ? <ValidateButton id={row.id} handleClick={handleValidate}/> : <></>}
                &nbsp;&nbsp;{row.can_delete ? <DeleteButton id={row.id} confirm={confirmDelete} />:<></> }
                &nbsp;&nbsp;{row.can_edit ? <UploadButton id={row.id} handleClick={openUploadModal} /> : <></>}
            </div>
    },
    {
      name: 'Date',
      selector: (row) => row.fund_date,
      sortable: true,
      
    },
    {
      name: 'Libellé',
      selector: (row) => row.name,
      sortable: true,
      filterable : true,
      
    },
    {
        name: 'Projet',
        selector: (row) => row.project_name,
        sortable: true,
        filterable : true,
    },
    {
        name: 'Element',
        selector: (row) => row.element_name,
        sortable: true,
        filterable : true,
    },
    {
        name: 'Paiement',
        selector: (row) => row.means_of_payment_name,
        sortable: true,
        filterable : true,  
    },
    {
        name: 'Montant',
        selector: (row) => row.amount,
        sortable: true,
        filterable : true,    
    }
  ];
  
  const handleDelete = () => {
    deleteCash(select).then((res) => {
        setMessage(res.message);
        setShowError(true);
    })
    setConfirm(false);
    setUpdate(true);
  }

  const handleEdit = (id) => {
    navigate(`/EditCash/${type}/${id}`);
  } 

  const handleNew = () => {
    navigate(`/AddCash/${type}`);
  } 

  const confirmDelete = (id) => {
    setSelect(id)
    setModalBody("Voulez-vous vraiment supprimer cette opération ?")
    setTitle("Suppression")
    setConfirm(true);
    setAction("suppression")
  }

  const openUploadModal = (id) => {
    setSelect(id);
    setModalBody(<Form className="theme-form" onSubmit={handleUpload} >
        <FormGroup>
            <Label>Fichier</Label>
            <Input className="form-control" 
                    name="file" 
                    type="file" 
                    multiple 
                    onChange={(event) => {setFiles(event.target.files)}}/>
        </FormGroup>
    </Form>)
    setTitle("Ajout de fichier")
    setConfirm(true);
    setAction("fichier")
  }

  const handleUpload = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
        formData.append(`files[${i}]`,files[i]);
    }
    formData.append("file_number", files.length);
    formData.append("cash_funds", select); 
    formData.append("file", files);
    addDocument(select, formData).then((res) => {
        console.log(res);
        setMessage(res.message);
        
        setShowError(true);
    })
    setConfirm(false);
  }

  const handleValidate = (id) => {
    ValidateCash(id).then((res) => {
        setMessage(res.message);
        setShowError(true);
        setUpdate(true);
    })
}

  useEffect(() => {
    listCash(type).then((result) => {
        setData(result)
        setUpdate(false);
    });
  }, [update])


  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title={header}/>
              <CardBody>
                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                    <CommonModal isOpen={confirm} 
                                 toggler={toggle} 
                                 title={title} 
                                 action={action == "suppression" ? handleDelete : handleUpload }
                                 message={modalBody}/>
                        <Row>
                            <Col md='3'>
                                <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'button', onClick:handleNew }}>Nouveau</Btn>
                            </Col>
                        </Row>
                        
                    <div className='table-responsive display' style={{marginTop: '2%' }}>
                        <CustomTable
                            data={data}
                            columns={tableColumns}
                        />
                    </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default Cash;