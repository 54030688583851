
import DataTable from 'react-data-table-component-with-filter';

const CustomTable = (props) => {

    return (
        <div className='table-responsive display'>
        <DataTable
            {...props}
            striped={true}
            sortable={true}
            left={true}
            noDataComponent="Aucune donnée trouvée"
            pagination={true}   
            responsive={true}
            paginationComponentOptions={{rowsPerPageText:'éléments par page', rangeSeparatorText:'dans'}}
        />
    </div>
    )
}

export default CustomTable;