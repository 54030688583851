import {axiosInstance, DOCUMENT_URL} from "./AxiosInstance";

export const getFiles = async () => {
    let data = await axiosInstance.get(`${DOCUMENT_URL}/`);
    return data.data
}

export const deleteFile = async (id) => {
    let data = await axiosInstance.delete(`${DOCUMENT_URL}/${id}`);
    return data.data
}

export const downloadFile = async (id) => {
    let data = await axiosInstance.post(`${DOCUMENT_URL}/${id}`, {"id": id});
    return data.data
}