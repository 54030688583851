import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from './AbstractElements';

const CommonModal = (props) => {
 
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'secondary', onClick: props.toggler }} >Annuler</Btn>
        <Btn attrBtn={{ color: 'primary', onClick: props.action }} >Valider</Btn>
      </ModalFooter>
    </Modal>
  );
};

export const Downloadmodal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
     {props.url && <ModalFooter>
        <a href={props.url} disabled={props.disabled} className="btn btn-success">Télécharger</a>
      </ModalFooter>}
    </Modal>
  );
}

export const BlotterDownloadModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
     <ModalFooter>
        <a href={props.url} disabled={props.disabled} className="btn btn-success">Télécharger</a>
  </ModalFooter>
    </Modal>
  );
}

export default CommonModal;


