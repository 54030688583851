import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { getAccounts, updateCashConfig, getCashConfig, convert_to_select_options  } from '../../Services/Settings.service';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { TYPE_ELEMENT, TYPE_MEANS_OF_PAYMENT } from '../../Params';
import Select from 'react-select';


const EditCashConfig = () => {
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 
    const [account, setAccount] = useState({});
    const [accounts, setAccounts] = useState([]);
    
    const  {type, id} = useParams();
    

    useEffect(() => {
        if(type !== undefined){
            getCashConfig(id).then((res)=>{
            setName(res.name);
            setAccount({label: res.account_name, value:res.account});
            })
        }
        
        
    },[type])

    useEffect(() => {
        getAccounts().then((res) => {
            setAccounts(convert_to_select_options(res, 'name'));
            
        })
    },[])

    useEffect(() => {
        let value = []
        switch(parseInt(type)){
            case TYPE_ELEMENT:
                value = [{'label': 'Elements', 'link': '/Elements'},{'label': "Edition d'un élément", 'link': '/EditElement/'+type+'/'+id}]
                break;
            case TYPE_MEANS_OF_PAYMENT:
                value = [{'label': 'Moyens de paiement', 'link': '/MOP'},{'label': "Edition d'un moyen de paiement", 'link': '/EditElement/'+type+'/'+id}]
                break;
            default:
                value = ["je suis perdu"]
                break;
        }
        if(value != []){
            setTitle(<Breadcrumbs elements={value}/>)
        }
        
    }, [type])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {name: name, account:account.value, type:type }
        updateCashConfig(id, data).then((response) => {
            setMessage(response.message)
            setShowError(true)
        })
    }


    return (
        <Fragment>
            {title}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`Paramètrage ${name}`}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" type="text" required="" 
                                                value={name}
                                                onChange={(e) => {setName(e.target.value)}}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Compte</Label>
                                        <Select options={accounts}
                                                value={account}
                                                onChange={(value) => {setAccount(value)}}
                                                className="js-example-basic-single col-sm-12"/>
                                    </FormGroup>
                    
                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Modifier</Btn>
                                    </FormGroup>
                                </Form>
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}


export default EditCashConfig;