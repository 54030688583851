const primary = "#d97f3d";
const secondary = "#26695c";

const ExpensesValues = [{'code': '60', 'label': 'Achat et variations de stock'},
                  {'code': '61', 'label': 'Transport'},
                  {'code': '62', 'label': 'Services Exterieurs'},
                  {'code': '63', 'label': 'Autres Services Exterieurs'},
                  {'code': '64', 'label': 'Impots et Taxes'},
                  {'code': '65', 'label': 'Autres Charges'},
                  {'code': '66', 'label': 'Charges de Personnel'},
                  {'code': '67', 'label': 'Frais Financiers et Charges Assimillées'},
                  {'code': '68', 'label': 'Dotations aux amortissements'},
                  {'code': '69', 'label': 'Dotations aux provisons et aux dépréciations'},]

const ProductsValues = [{'code': '70', 'label': 'Ventes'},
                  {'code': '71', 'label': 'Subvension d\'exploitation'},
                  {'code': '72', 'label': 'Production immobilisée'},
                  {'code': '73', 'label': 'Variations de Stocks de biens et de services produits'},
                  {'code': '75', 'label': 'Autres produits'},
                  {'code': '77', 'label': 'Revenus financiers et assimilés'},
                  {'code': '78', 'label': 'Transferts de charges'},
                  {'code': '79', 'label': 'Reprises de provisons , de dépréciations et autres'},]

export const expenseOptions = {
    options: {
        chart: {
          height: 360,
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            rangeBarOverlap: true,
            columnWidth: '60%',
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['60', '61', '62', '63', '64', '65', '66', '67', '68', '69']
        },
        yaxis: {
          title: {
            text: 'montant'
          }
        },
    
        fill: {
          opacity: 1,
          colors: [primary],
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 0.9,
            opacityTo: 0.8,
            stops: [0, 100]
          }
        },
        colors: [primary],
        tooltip: {
          y: {
            formatter: function (val, data) {
              return ' ' + ExpensesValues[data.dataPointIndex].label + ' '+ '<br/>' + val+' CFA';
            }
          }
        }
      }
    };


export const productOptions = {
        options: {
            chart: {
              height: 360,
              type: 'bar',
              toolbar: {
                show: false
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                rangeBarOverlap: true,
                columnWidth: '60%',
                colors: {
                  ranges: [{
                    from: 0,
                    to: 0,
                    color: undefined
                  }],
                  backgroundBarColors: [],
                  backgroundBarOpacity: 1,
                  backgroundBarRadius: 0,
                },
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['70', '71', '72', '73', '75', '77', '78', '79']
            },
            yaxis: {
              title: {
                text: 'montant'
              }
            },
        
            fill: {
              opacity: 1,
              colors: [secondary],
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 0.9,
                opacityTo: 0.8,
                stops: [0, 100]
              }
            },
            colors: [secondary],
            tooltip: {
              y: {
                formatter: function (val, data) {
                  return ' ' + ProductsValues[data.dataPointIndex].label + '<br/>' + val+' CFA';
                }
              }
            }
          }
        };
    
 export const global ={
    options: {
            chart: {
              height: 350,
              type: 'radialBar',
              
            },
            plotOptions: {

              radialBar: {
                
                hollow: {
                  margin: 15,
                  size: '70%',
                  image: require('../assets/images/email-template/success.png'),
                  imageWidth: 64,
                  imageHeight: 64,
                  imageClipped: false
                },
                
                dataLabels: {
                  name: {
                    show: false,
                    color: primary
                  },
                  value: {
                    show: true,
                    color: primary,
                    offsetY: 70,
                    fontSize: '22px'
                  }
                }
              },
              
            },
            fill: {
              colors : [primary],
              //type: 'image',
              //image: {
              //  src: [require('../assets/images/user-card/5.jpg')],
             // }
            },
            stroke: {
              lineCap: 'round'
            },
            responsive: [
              {
                breakpoint: 360,
                options: {
                  chart: {
                    height: 300
                  }
                }
              }
            ],
            labels: ['Volatility'],
    
        }
}