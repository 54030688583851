import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { getAccounts, updateJournal, getJournals, convert_to_select_options } from '../../Services/Settings.service';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Select from 'react-select';

const EditJournal = () => {
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 
    const [code, setCode] = useState("");
    const [account, setAccount] = useState({});
    const [accounts, setAccounts] = useState([]);
    
    let {id} = useParams()
    const elements = [{'label': 'Journaux', 'link': '/Journals'}, {'label': 'Edition du journal', 'link': '/EditJournal/'+id}]

    useEffect(() => {
        getJournals(id).then((res)=>{
            setName(res.name);
            setCode(res.code);
            setAccount({label: res.account_name, value:res.compte})
        })
        
    },[])

    useEffect(() => {
        getAccounts().then((res) => {
            setAccounts(convert_to_select_options(res, 'name'));
        })
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {'name': name, "code":code, compte:account.value }
        updateJournal(id, data).then((response) => {
            setMessage(response.message)
            setShowError(true)
        })
    }


    return (
        <Fragment>
            <Breadcrumbs elements={elements} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`Paramètrage du journal ${code}`}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <Input className="form-control" 
                                                name="code" type="text" required="" value={code} 
                                                onChange={(e) => {setCode(e.target.value)}}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" type="text" required="" 
                                                value={name}
                                                onChange={(e) => {setName(e.target.value)}}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Compte</Label>
                                        <Select options={accounts}
                                                value={account}
                                                onChange={(value) => {setAccount(value)}}
                                                className="js-example-basic-single col-sm-12"/>
                                    </FormGroup>
                    
                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Modifier</Btn>
                                    </FormGroup>
                                </Form>
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}


export default EditJournal;