import {axiosInstance, CASH_URL, ACCOUNTING_URL} from "./AxiosInstance";

// cash settings config


export const listCash = async (type) => {
    let res = await axiosInstance.get(`${CASH_URL}/cashfunds/cash_type/${type}/`)
    return res.data
}

export const getCash = async (id) => {
    let res = await axiosInstance.get(`${CASH_URL}/cashfunds/${id}/`)
    return res.data
}

export const addCash = async (data, cash_type) => {
    let res = await axiosInstance.post(`${CASH_URL}/cashfunds/cash_type/${cash_type}/`, data)
    return res.data
}

export const updateCash = async(id, data) => {
    let res = await axiosInstance.put(`${CASH_URL}/cashfunds/${id}/`, data)
    return res.data
}

export const deleteCash = async(id) => {
    let res = await axiosInstance.delete(`${CASH_URL}/cashfunds/${id}/`)
    return res.data
}

export const ValidateCash = async(id) => {
    let res = await axiosInstance.patch(`${CASH_URL}/validate_funds/${id}/`)
    return res.data
}

export const addDocument = async(id, data)=> {
    let res  = await axiosInstance.post(`/edm/cash_document/${id}/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }})
    return res.data
}

export const convert_to_select_options = (data) => {
    let converted_data = []
    data.map((res) => {
        converted_data.push({value: res.id, label:`${res.name}`})
    })
    return converted_data
}

export const getBlotter = async (type, start, end) => {
    let params = (start ? '?start_date='+start : '');
    params += (end ? '&end_date='+end : '');
    let res = await axiosInstance.get(`${CASH_URL}/cashblotter/type/${type}/${params}`)
    return res.data
}

export const downloadBlotter = async (type, file_format, start_date, end_date) => {
    let params = `?file_format=${file_format}`
    params += (start_date ? '&start_date='+start_date : '');
    params += (end_date ? '&end_date='+end_date : '');
    let res = await axiosInstance.get(`${CASH_URL}/cashblotter/type/${type}/file/${params}`)
    return res.data
}

// EVALUATIONS SERVICES

export const getEval = async (id) => {
    let res = await axiosInstance.get(`${CASH_URL}/evaluation/${id}/`)
    return res.data
}

export const listEval = async (type) => {
    let res = await axiosInstance.get(`${CASH_URL}/evaluation/eval_type/${type}/`)
    return res.data
}

export const addEval = async (type, data) => {
    let res = await axiosInstance.post(`${CASH_URL}/evaluation/eval_type/${type}/`, data)
    return res.data
}

export const updateEval = async(id, data) => {
    let res = await axiosInstance.put(`${CASH_URL}/evaluation/${id}/`, data)
    return res.data
}

export const deleteEval = async(id) => {
    let res = await axiosInstance.delete(`${CASH_URL}/evaluation/${id}/`)
    return res.data
}

export const ValidateEval = async(eval_id) => {
    let res = await axiosInstance.patch(`${CASH_URL}/validate_evaluation/${eval_id}/`)
    return res.data
}

export const getStatement = async () => {
    let res = await axiosInstance.get(`${ACCOUNTING_URL}/financial_statement/`)
    return res.data
}

export const getFundReport = async () => {
    const res = await axiosInstance.get(`${CASH_URL}/fund_report/`)
    return res.data
}
