import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import LoginForm from "./LoginForm";
import '../assets/css/custom.css';



const Logins = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    let token = queryParameters.get("appToken");

    return (
        <Fragment>
           <section>
                <Container fluid={true}>
                    <Row>
                        <Col xl="7" className="bg-login">
                        </Col>
                        <Col xl="5 p-0">
                            <LoginForm iwoToken={token} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment >
    )
}

export default Logins;