import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Breadcrumbs, Btn, Alerts } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { getAccounts, getJournals, evalConfig, convert_to_select_options, updateConfig  } from '../../Services/Settings.service';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

const EditEvaluation = () => {
    const [name, setName] = useState("");
    const [accounts, setAccounts] = useState([])
    const [ journals, setJournals] = useState([])
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 
    const [debit, setDebit] = useState({});
    const [credit, setCredit] = useState({});
    const [journal, setJournal] = useState({});

    let {type, id} = useParams()

    useEffect(() => {
        getAccounts().then((res)=>{setAccounts(convert_to_select_options(res, 'nom'))})
    },[]);

    useEffect(() => {
        getJournals().then((res)=>{setJournals(convert_to_select_options(res, 'nom'))})
    },[])

    useEffect(() => {
        evalConfig(type).then((res)=>{
            let result = res[0]
            setName(result.name)
            setDebit({label:result.debit_account_name, value:result.debit_account})
            setCredit({label:result.credit_account_name, value:result.credit_account})
            setJournal({label:result.journal_name, value:result.journal})
        })
        
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {'name': name, "debit_account":debit.value, "credit_account":credit.value, 'journal': journal.value}
        updateConfig(id, data, type).then((response) => {
            console.log(response)
            setMessage(response.message)
            setShowError(true)
        })
    }


    return (
        <Fragment>
            <Breadcrumbs parent="Evaluation" title={name} mainTitle="Paramètrages" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`Paramètrage ${name}`}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" type="text" 
                                                required="" value={name}
                                                onChange={(e) => {setName(e.target.value)}}
                                                />
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Compte de débit</Label>
                                        <Select options={accounts}
                                                value= {debit}
                                                onChange={(value => {setDebit(value)})}
                                                className="js-example-basic-single col-sm-12"/>
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Compte de crédit</Label>
                                        <Select options={accounts} 
                                                className="js-example-basic-single col-sm-12"
                                                value= {credit}
                                                onChange={(value => {setCredit(value)})}
                                                />
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Journal</Label>
                                        <Select options={journals} 
                                                className="js-example-basic-single col-sm-12"
                                                value= {journal}
                                                onChange={(value => {setJournal(value)})}
                                                />
                                    </FormGroup>
                    
                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Modifier</Btn>
                                    </FormGroup>
                                </Form>
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}
 



export default EditEvaluation;