import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../AbstractElements';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import { addCash, convert_to_select_options } from '../Services/funds.services';
import { listCashConfig } from '../Services/Settings.service';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { TYPE_ELEMENT, TYPE_MEANS_OF_PAYMENT, INFLOW, OUTFLOW } from '../Params';
import Breadcrumbs from '../Common/Breadcrumbs';
import DatePicker from 'react-datepicker';

const AddCash = () => {
    const [name, setName] = useState("");
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [element, setElement] = useState({});
    const [mop, setMOP] = useState({});
    const [elements, setElements] = useState([]);
    const [mops, setMOPs] = useState([]);
    const [project, setProject] = useState({});
    const [projects, setProjects] = useState([]);
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    const [title, setTitle] = useState("");
    const [label, setLabel] = useState("");

    let {type} = useParams()

    useEffect(() => {
        let value = []
        switch(parseInt(type)){
            case INFLOW:
                value = [{'label': 'Entrée de fond', 'link': `/Cash/${INFLOW}`},{'label': "Ajout d'une entrée de fonds", 'link': '/AddFunds/'+type}]
                setLabel("Ajout d'une entrée de fonds");
                break;
            case OUTFLOW:
                value = [{'label': 'Sortie de fond', 'link': `/Cash/${OUTFLOW}`},{'label': "Ajout d'une sortie de fonds", 'link': '/AddFunds/'+type}]
                setLabel("Ajout d'une sortie de fonds")
                break;
            default:
                value = "je suis perdu"
                break;
        }
        setTitle(<Breadcrumbs elements={value}/>)
    }, [])
    

    useEffect(() => {
        listCashConfig(TYPE_ELEMENT).then((res)=>{setElements(convert_to_select_options(res))})
    },[]);

    useEffect(() => {
        listCashConfig(TYPE_MEANS_OF_PAYMENT).then((res)=>{setMOPs(convert_to_select_options(res))})
    },[]);

   


    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('element', element.value);
        formData.append('date', parseInt((new Date(date).getTime() / 1000).toFixed(0)));
        formData.append('means_of_payment', mop.value);
        formData.append('name',name);
        formData.append('amount', amount);
        if (project.value){
            formData.append('project', project.value)
        }
        
        for (let i = 0; i < files.length; i += 1) {
            formData.append(`files[${i}]`, files[i]);
        }
        formData.append("file_number", files.length);
        addCash(formData, type).then((res) => {
            setMessage(res.message);
            setShowError(true);
        }).catch(error => {
            let message = ''
            if(error.status_code === 400){
                
                for (const [key, value] of Object.entries(error.response.data.message)) {
                    message += `${value}. `;
                }
            }
            else{
                message = error
            }
            setMessage(message);
            setShowError(true);
        })
    }


    return (
        <Fragment>
            {title}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={label}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    
                                    <FormGroup>
                                        <Label>Date</Label>
                                        <DatePicker className="form-control digits" 
                                                required="" 
                                                selected={date}
                                                onChange={(da) => {setDate(da)}}
                                                dateFormat='dd/MM/yyyy'
                                                />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" 
                                                type="text" 
                                                required=""
                                                onChange={(e) => {setName(e.target.value)}}
                                                />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Montant</Label>
                                        <Input className="form-control" 
                                                name="name" 
                                                type="number" 
                                                min="0" 
                                                onChange={(e) => {setAmount(e.target.value)}}
                                                />
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Eléments</Label>
                                        <Select options={elements}
                                                onChange={(value => {setElement(value)})}
                                                className="js-example-basic-single col-sm-12"/>
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Moyens de paiement</Label>
                                        <Select options={mops}
                                                onChange={(value => {setMOP(value)})}
                                                className="js-example-basic-single col-sm-12"/>
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Projet</Label>
                                        <Select options={projects} 
                                                className="js-example-basic-single col-sm-12"
                                                onChange={(value => {setProject(value)})}
                                                />
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label>Pieces jointes</Label>
                                        <Input type="file" 
                                                name="files"
                                                multiple 
                                                onChange={(event) => {setFiles(event.target.files)}} 
                                                />
                                    </FormGroup>
                    
                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Ajouter</Btn>
                                    </FormGroup>
                                </Form>
                                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}
 



export default AddCash;