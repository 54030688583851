
import FiscalYear from '../Settings/FiscalYear';
import EditEvaluation from '../Settings/Evaluations/EditEvaluation';
import Evaluation from '../Settings/Evaluations';
import Accounts from '../Settings/Accounts';
import EditAccount from '../Settings/Accounts/EditAccount';
import Journals from '../Settings/Journal';
import EditJournal from '../Settings/Journal/EditJournal';
import Elements from '../Settings/Cash/Elements';
import EditCashConfig from '../Settings/Cash/EditCashConfig';
import MOP from '../Settings/Cash/MOP';
import Projects from '../Settings/Projects';
import EditProject from '../Settings/Projects/EditProject';
import Cash from '../Cash/Cash';
import AddCash from '../Cash/AddCash';
import EditCash from '../Cash/EditCash';
import Blotter from '../Blotter';
import Eval from '../Eval';
import AddEval from '../Eval/AddEval';
import EditEval from '../Eval/EditEval';
import ExportStatment from '../Statements';
import Dashboard from '../Dashboard';
import Files from '../Files';
import AddCustomer from '../Customer/AddCustomer';
import LoginForm from '../Auth/LoginForm';
import Signin from '../Auth/Signin';
import FundReport from '../FundReport';


export const routes = [

        // my routes
        { path: '/SignIn/:IwoToken/', Component: <Signin />},
        { path: '/FiscalYear', Component: <FiscalYear /> },
        { path: '/Evaluations', Component: <Evaluation /> },
        { path: '/EditEvaluation/:type/:id/', Component: <EditEvaluation /> },
        { path: '/Accounts', Component: <Accounts /> },
        { path: '/EditAccount/:id/', Component: <EditAccount /> },
        { path: '/Journals', Component: <Journals /> },
        { path: '/EditJournal/:id/', Component: <EditJournal /> },
        { path: '/Elements', Component: <Elements /> },
        { path: '/EditCashConfig/:type/:id/', Component: <EditCashConfig /> },
        { path: '/MOP', Component: <MOP /> },
        { path: '/Projects', Component: <Projects /> },
        { path: '/EditProject/:id/', Component: <EditProject /> },
        { path: '/Cash/:type', Component: <Cash /> },
        { path: '/Cash/:type', Component: <Cash /> },
        { path: '/AddCash/:type', Component: <AddCash /> },
        { path: '/EditCash/:type/:id', Component: <EditCash /> },
        { path: '/Blotter/:type', Component: <Blotter /> },
        { path : '/Eval/:type', Component: <Eval />},
        { path : '/AddEval/:type', Component: <AddEval />},
        { path : '/EditEval/:type/:id/', Component: <EditEval />},
        { path: '/Statement', Component: <ExportStatment /> },
        { path: '/Dashboard', Component: <Dashboard />},
        { path: '/Files/', Component: <Files />},
        { path: '/AddCustomer/', Component: <AddCustomer />},
        { path: '/FundReport/', Component: <FundReport />},
];
