import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../AbstractElements';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../Common/Breadcrumbs';
import { addAccounts, addCashConfig } from '../Services/Settings.service';
import { TYPE_ELEMENT } from '../Params';

const AddCustomer = () => {
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [label, setLabel] = useState("Nouveau client");
    const [showError, setShowError] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        addAccounts({name:name, code:code}).then((res) => {
            if(res["id"] !== undefined){
                addCashConfig(TYPE_ELEMENT, {name:name, account:res['id']}).then((result) => {
                    setMessage(result.message)
                });
            }
            else {
                setMessage(res['message']);
            }
            setShowError(true);
        });
    }


    return (
        <Fragment>
            {title}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={label}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" 
                                                type="text" 
                                                required=""
                                                onChange={(e) => {setName(e.target.value)}}
                                                />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code Compte</Label>
                                        <Input className="form-control" 
                                                name="code" 
                                                type="text" 
                                                required=""
                                                onChange={(e) => {setCode(e.target.value)}}
                                                />
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Ajouter</Btn>
                                    </FormGroup>
                                </Form>
                                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}

export default AddCustomer;