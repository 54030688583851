import React, { useContext, useState, Fragment } from 'react';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import CheckContext from '../../_helper/Customizer';

const Leftbar = () => {

    const { mixLayout, toggleSidebar, toggleIcon } = useContext(CheckContext);
    const [toggle, setToggle] = useState(false);
    const logo = localStorage.getItem('company_logo');
    const company_name = localStorage.getItem('company_name');

    const openCloseSidebar = () => {
        setToggle(!toggle);
        toggleSidebar(toggle);
    };

    return (
        <Fragment>
            <div className="main-header-left">
                <div className="logo-wrapper">
                    <Link to='/dashboard' className='txt-primary'>
                        <Image attrImage={{ className: 'img-fluid d-inline media', src: logo, alt: `${company_name} caisse`, style:{'width': '50 px', 'height': '50px', 'fontWeight': 'bold'} }} /> 
                    </Link>
                </div>
                    
                <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
                    <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
                </div>
            </div>
        </Fragment >
    );
};

export default Leftbar;