import React, { Fragment, useState, useEffect } from 'react';
import CustomTable from '../CustomTables';
import { Container, Row, Col, Card, CardBody, Form, Dropdown, DropdownMenu, DropdownItem, Button, ButtonGroup} from 'reactstrap';
import {  Btn } from '../AbstractElements';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import { BLOTTER_TYPE_BANK, BLOTTER_TYPE_CASH } from '../Params';
import { useParams } from "react-router-dom";
import { getBlotter, downloadBlotter } from '../Services/funds.services';
import DatePicker, {setDefaultLocale} from 'react-datepicker';
import { get_infos } from '../Services/Cash.services';
import { BlotterDownloadModal } from '../CommonModal';

setDefaultLocale('fr');



const Blotter = () => {

  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [modalBody, setModalBody] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState(0);
  const [startTS, setStartTS] = useState(0);
  const [endTS, setEndTS] = useState(0);
  const [url, setUrl] = useState(0);
  const [disable, setDisable] = useState(false);
  const [update, setUpdate] = useState(false);
  const [title, setTitle] = useState("");

  const toggle = () => setConfirm(!confirm);


  const {type} = useParams();


  useEffect(()=> {
    
    if(parseInt(type) === BLOTTER_TYPE_BANK){
        setTitle("Brouillar de banque");
    }
    else if(parseInt(type) === BLOTTER_TYPE_CASH){
        setTitle("Brouillar de caisse");
    }
  }, [type]);

  useEffect(() => {
    get_infos().then((response) => {
        setStart(new Date(`01/01/${response.exercise_year}`));
        setEnd(new Date(`12/31/${response.exercise_year}`));
        setStartTS(parseInt((new Date(`01/01/${response.exercise_year}`).getTime() / 1000).toFixed(0)));
        setEndTS(parseInt((new Date(`12/31/${response.exercise_year}`).getTime() / 1000).toFixed(0)));
   });
    
  },[]);

  const tableColumns = [
    {
        name: 'Date',
        selector: (row) => row.fund_date,
        sortable: true,
        
    },
    {
      name: 'Libellé',
      selector: (row) => row.name,
      sortable: true,
      
    },
    {
      name: 'Entrée',
      selector: (row) => row.amount_in,
      sortable: true,
      
    },
    {
        name: 'Sortie',
        selector: (row) => row.amount_out,
        sortable: true,
        
    },
    {
        name: 'Solde',
        selector: (row) => row.balance,
        sortable: true,
        
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setStartTS(parseInt((new Date(start).getTime() / 1000).toFixed(0)));
    setEndTS(parseInt((new Date(end).getTime() / 1000).toFixed(0)));
    setUpdate(true);
  }

  const handleDownload = (e,format) =>{
    e.preventDefault();
    const data = {file_format: format, start_date:startTS, end_date:endTS}
    setModalBody("Votre document est en cours de téléchargement");
    setConfirm(true);
    downloadBlotter(type, format, startTS, endTS).then((res) => {
        setModalBody("Votre document est prêt");
        setDisable(false)
        setUrl(res.path)
    })
  }

  useEffect(() => {
    getBlotter(type, startTS, endTS ).then((result) => {
        setData(result);
        setUpdate(false);
    });
  }, [update])



  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title={title}/>
              <CardBody>
                    <BlotterDownloadModal isOpen={confirm} 
                                 toggler={toggle} 
                                 title={`Téléchargment du ${title}`}
                                 url={url}
                                 disabled= {disable}
                                 message={modalBody}/>
                    <Form className="needs-validation" >
                        <Row className="g-3">
                            <Col md='3'>
                            <DatePicker className="form-control digits" 
                                        required="" 
                                        selected={start}
                                        onChange={(st) => {setStart(st)}}
                                        dateFormat='dd/MM/yyyy'
                            /> 
                            </Col>
                            <Col md='3'>
                            <DatePicker className="form-control digits" 
                                        required="" 
                                        selected={end}
                                        onChange={(en) => {setEnd(en)}}
                                        dateFormat='dd/MM/yyyy'
                            /> 
                            </Col>
                            <Col md='2'>
                                <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit', onClick: handleSearch }}>Rechercher</Btn>
                            </Col>
                            <Col md='2'>
                                <ButtonGroup>
                                  <Button className='btn btn-success' type='btn' onClick={(e)=> handleDownload(e,"excel") }>Excel</Button>
                                  <Button className='btn btn-danger' type='btn' onClick={(e)=> handleDownload(e,"pdf") }>PDF</Button>
                                </ButtonGroup>
                               
                            </Col>
                            

                        </Row>
                    </Form>        
                    <div className='table-responsive display' style={{marginTop: '2%' }}>
                        <CustomTable
                            data={data}
                            columns={tableColumns}
                        />
                    </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default Blotter;