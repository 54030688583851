import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Alerts } from '../AbstractElements';
import HeaderCard from '../Components/Common/Component/HeaderCard';
import { getEval, updateEval } from '../Services/funds.services';
import { useParams } from 'react-router-dom';
import { TYPE_AMORTIZATION, TYPE_CLAIM, TYPE_STOCK, TYPE_DEBT } from '../Params';
import Breadcrumbs from '../Common/Breadcrumbs';
import DatePicker from 'react-datepicker';

const EditEval = () => {
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [amount, setAmount] = useState("");
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    const [title, setTitle] = useState("");
    const [label, setLabel] = useState("");

    let {type, id} = useParams()

    useEffect(() => {
        let value = []
        let url = `/EditEval/${type}/${id}`
        switch(parseInt(type)){
            case TYPE_AMORTIZATION:
                value = [{'label': 'Amortissements', 'link': `/Eval/${TYPE_AMORTIZATION}`},{'label': "Modification amortissement", 'link': url}]
                setLabel("Modification Amortissement");
                break;
            case TYPE_STOCK:
                value = [{'label': 'Stock', 'link': `/Eval/${TYPE_STOCK}`},{'label': "Modification stock", 'link': url}]
                setLabel("Modification stock");
                break;
            case TYPE_DEBT:
                value = [{'label': 'Dettes', 'link': `/Eval/${TYPE_DEBT}`},{'label': "Modification dettes", 'link': url}]
                setLabel("Modification dettes");
                break;
            case TYPE_CLAIM:
                value = [{'label': 'créances', 'link': `/Eval/${TYPE_CLAIM}`},{'label': "Modification créance", 'link': url}]
                setLabel("Modification créances");
                break;
            default:
                value = "je suis perdu"
                break;
        }
        setTitle(<Breadcrumbs elements={value}/>)
    }, [])
    
   
    useEffect(() => {
        getEval(id).then((res)=>{
            let result = res.data;
            setDate(new Date((result.date * 1000) + 1000))
            setName(result.name)
            setAmount(result.amount)
        })
        
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('type', type);
        formData.append('date', parseInt((new Date(date).getTime() / 1000).toFixed(0)));
        formData.append('name',name);
        formData.append('amount', amount);
        updateEval(id, formData).then((res) => {
            setMessage(res.message);
            setShowError(true);
        })
    }


    return (
        <Fragment>
            {title}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={label}/>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                    <Form className="theme-form" onSubmit={handleSubmit} >
                                    
                                    <FormGroup>
                                        <Label>Date</Label>
                                        <DatePicker className="form-control digits" 
                                                required="" 
                                                selected={date}
                                                onChange={(da) => {setDate(da)}}
                                                dateFormat='dd/MM/yyyy'
                                                />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input className="form-control" 
                                                name="name" 
                                                type="text" 
                                                required=""
                                                value={name}
                                                onChange={(e) => {setName(e.target.value)}}
                                                />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Montant</Label>
                                        <Input className="form-control" 
                                                name="name" 
                                                type="number" 
                                                min="0" 
                                                value={amount}
                                                onChange={(e) => {setAmount(e.target.value)}}
                                                />
                                    </FormGroup>

                                    <FormGroup>
                                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Modifier</Btn>
                                    </FormGroup>
                                </Form>
                                {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            </Container>
        </Fragment>                
    )

}
 



export default EditEval;