import React, { Fragment, useState, useEffect } from 'react';
import CustomTable from '../../CustomTables';
import { Container, Row, Col, Card, CardBody, Form, Input } from 'reactstrap';
import { Btn, Alerts } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { addCashConfig, deleteCashConfig, listCashConfig, getAccounts, convert_to_select_options } from '../../Services/Settings.service';
import { DeleteButton, EditButton } from '../../CustomButton';
import { useNavigate } from "react-router-dom";
import CommonModal from '../../CommonModal';
import Select from 'react-select';
import { TYPE_MEANS_OF_PAYMENT } from '../../Params';


const MOP = () => {

  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [select, setSelect] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false); 
  const [update, setUpdate] = useState(false);
  const [account, setAccount] = useState({})
  const [accounts, setAccounts] = useState([])

  const toggle = () => setConfirm(!confirm);

  const navigate = useNavigate();

  const tableColumns = [
    {
        name:'Actions',
        selector: (row) => 
            <div>
                <EditButton id={row.id} handleClick={handleEdit} />
                &nbsp;&nbsp;<DeleteButton id={row.id} confirm={confirmDelete} />
            </div>
    },
    {
      name: 'Nom',
      selector: (row) => row.name,
      sortable: true,
      filterable : true,
      
    },
    {
      name: 'Compte',
      selector: (row) => row.account_name,
      sortable: true,
      filterable : true,
    },
   
  ];
  
  const handleDelete = () => {
    
    deleteCashConfig(select).then((res) => {
        setMessage(res.message);
        setShowError(true);
        setUpdate(true);
    })
    setConfirm(false);
    
  }

  const handleEdit = (id) => {
    navigate(`/EditCashConfig/${TYPE_MEANS_OF_PAYMENT}/${id}`);
  } 

  const confirmDelete = (id) => {
    setSelect(id)
    setConfirm(true);
  }

  useEffect(() => {
    listCashConfig(TYPE_MEANS_OF_PAYMENT).then((result) => {
        setData(result)
        setUpdate(false);
    });
  }, [update])

  useEffect(() => {
    getAccounts().then((res) => {
        setAccounts(convert_to_select_options(res, 'name'));
    })
  },[])

  const newConfig = (e) => {
    e.preventDefault();
    addCashConfig(TYPE_MEANS_OF_PAYMENT, {name:name, account:account.value}).then((res) => {
        setMessage(res.message);
        setShowError(true);
        setUpdate(true);
    })
  }

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Moyens de paiement"/>
              <CardBody>
                    {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                    <CommonModal isOpen={confirm} 
                                 toggler={toggle} 
                                 title="Supression" 
                                 action={handleDelete}
                                 message="Voulez-vous vraiment suprrimer cet moyen de paiement ?"/>
                    <Form className="needs-validation" noValidate="" onSubmit={newConfig}>
                        <Row className="g-3">
                            <Col md='3'>
                                <Input 
                                    className="form-control" 
                                    name="name" 
                                    type="text" 
                                    required={true} 
                                    placeholder="libellé" 
                                    onChange={(e) => setName(e.target.value)}
                                    />
                            </Col>
                            <Col md='3'>
                                <Select options={accounts}
                                        onChange={(value) => {setAccount(value)}}
                                        className="js-example-basic-single col-sm-12"/>
                            </Col>
                            <Col md='3'>
                                <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Ajouter</Btn>
                            </Col>
                        </Row>
                    </Form>        
                    <div className='table-responsive display' style={{marginTop: '2%' }}>
                        <CustomTable
                            data={data}
                            columns={tableColumns}
                        />
                    </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default MOP;