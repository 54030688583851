import React, { useEffect, useState, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../CommonElements/Headings/H3Element';


const Breadcrumbs = (props) => {
    const [data, setData] = useState([])

    const elements = props.elements;
    
    useEffect(() => {
        let result = []
        elements.map((element, index) => {
            result.push(<li className="breadcrumb-item"><Link  to={element.link}>{element.label} </Link></li>) 
        });
        setData(result)

    }, [])


    return (
        <Fragment>
            <Container fluid={true}>
                <div className="page-header">
                <Row>
                    <Col sm="6">
                        <H3>{props.mainTitle}</H3>
                    </Col>
                    <Col sm="6">
                        <ol className="breadcrumb">
                            {data.map(res => res)}
                        </ol>
                    </Col>  
                </Row>
                </div>
            </Container>     
        </Fragment>         
    )
}

export default Breadcrumbs;