import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, Input, Form  } from 'reactstrap';
import { Breadcrumbs, Alerts, Btn } from '../../AbstractElements';
import CommonModal from '../../CommonModal';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { addFiscalYear, deleteFiscalYear, fiscalYear, changeFiscalYear, closeFiscalYear } from '../../Services/Settings.service'
import { DeleteButton, ActivateButton, ValidateFiscal } from '../../CustomButton';

const FiscalYear = () => {

  const [data, setData] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false); 
  const [update, setUpdate] = useState(false);
  const [select, setSelect] = useState(0);
  const [year, setYear] = useState(0);

  const toggle = () => setConfirm(!confirm);

  const tableColumns = [
    {
        name:'Actions',
        selector: (row) =><>
          <DeleteButton id={row.id} confirm={confirmDelete} />
          &nbsp; &nbsp;{row.default ? <></> : <ActivateButton id={row.id} confirm={defaultFiscalYear}/>}
          {row.default ? <ValidateFiscal id={row.id} handleClick={closeFiscal}/> : <></>}
        </> 
    },
    {
      name: 'Exercice',
      selector: (row) => row.annee,
      sortable: true,
    },
    {
      name: 'En cours',
      selector: (row) => row.default ? "Oui": "Non",
      sortable: true,  
    },
  ];

  const handleDelete = () => {
    
    deleteFiscalYear(select).then((res) => {
        setMessage(res.message);
        setShowError(true);
        setUpdate(true);
    })
    setConfirm(false);
    
  }

  const confirmDelete = (id) => {
    setSelect(id)
    setConfirm(true);
  }


  useEffect(() => {
    fiscalYear().then((result) => {
        setData(result)
    });
  }, [update])

  const newFiscalYear = (e) => {
    e.preventDefault();
    
    addFiscalYear({annee:year}).then((res) => {
        setMessage(res.message);
        setShowError(true);
        setUpdate(true);
    })
  }

  const defaultFiscalYear = (id) => {
    changeFiscalYear(id).then((res) => {
      setMessage(res.message);
      window.location.reload(false);
  })
  }

  const closeFiscal = (id) => {
    closeFiscalYear(id).then((res) => {
      setMessage(res.message);
      window.location.reload(false);
    })
  }


  return (
    <Fragment>
      <Breadcrumbs parent="Paramètres" title="Comptes" mainTitle="Exercices comptables" />
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Exercices comptable"/>
              <CardBody>
                {showError && (<Alerts attrAlert={{color:'primary '}} children={message} />)}
                    <CommonModal isOpen={confirm} 
                                 toggler={toggle} 
                                 title="Supression" 
                                 action={handleDelete}
                                 message="Voulez-vous vraiment suprrimer cette exercice ?"/>
                    <Form className="needs-validation" noValidate="" onSubmit={newFiscalYear}>
                        <Row className="g-3">
                            <Col md='3'>
                                <Input 
                                    className="form-control" 
                                    name="name" 
                                    type="text" 
                                    required={true} 
                                    placeholder="Année" 
                                    onChange={(e) => setYear(e.target.value)}
                                    />
                            </Col>
                            <Col md='3'>
                                <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Ajouter</Btn>
                            </Col>
                        </Row>
                    </Form>        
                    <div className='table-responsive display' style={{marginTop: '2%' }}>
                        <DataTable
                            className='display'
                            data={data}
                            columns={tableColumns}
                            striped={true}
                            sortable={true}
                            left={true}
                            noDataComponent="Aucune donnée trouvée"
                            pagination={true}
                            paginationServer
                            responsive={true}
                            paginationComponentOptions={{rowsPerPageText:'éléments par page', rangeSeparatorText:'dans'}}
                        />
                    </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default FiscalYear;