import axios from "axios";


export const siteURL = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return "http://localhost:8000"
    }
    else {
        return "https://iwo.bj";
    }
} 

export const company_id = localStorage.getItem('company_id');
export const entity_id = localStorage.getItem('entity_id')

export const CASH_URL = `/cash/entity/${entity_id}`
export const ACCOUNTING_URL = `/accounting/company/${company_id}`
export const DOCUMENT_URL = `/edm/cash_document/${company_id}`

const token = localStorage.getItem("IwoCashToken")

export const axiosInstance = axios.create({
    baseURL: siteURL(),
    headers: {
        "Authorization": "Token "+token, 
        'Content-Type': 'multipart/form-data',
        'Accept' : 'application/json'
    },
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
    console.log("error "+ error)
    return Promise.reject(error);
    // window.location.href = "/Login"
});

export default axiosInstance;